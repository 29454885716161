.project-card-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 500px; /* Fixed height for all cards */
  width: 100%; /* Ensure full width within the container */
  filter: none !important; /* Explicitly remove any filter */
  opacity: 1 !important; /* Ensure full opacity */
  transition: none !important; /* Completely disable transition */
  text-align: left;
  direction: ltr;
}

/* RTL layout adjustments */
.project-card-view.rtl {
  text-align: right;
  direction: rtl;
}


.project-card-view:hover,
.project-card-view:focus,
.project-card-view:active,
.project-card-view:focus-within {
  transform: scale(1.02); /* Subtle zoom effect on hover */
  filter: none !important; /* Ensure no filter is applied on hover */
  opacity: 1 !important; /* Ensure full opacity on hover */
  transition: none !important; /* Disable transition on hover */
}

/* Card footer styles */
/* Default footer styling for status and button */
.project-card-footer {
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  padding: 10px;
  background-color: transparent;
  border-top: 1px solid #a24dd386;
  margin-top: auto;
  width: 100%;
  filter: none !important;
  opacity: 1 !important;
  transition: none !important;
  gap: 30px !important; /* Add spacing between icons */
}

/* Center the icons when the status is "Published" */
.project-card-footer.center-icons {
  justify-content: center; /* Center the icons horizontally */
  gap: 10px; /* Add spacing between icons */
}

.project-card-footer p {
  margin: 0; /* Remove default margin for the status text */
  text-align: center; /* Center the status text */
}

/* Center the status text if there is no live button */
.project-card-footer.center-status {
  justify-content: center; /* Centers the status text */
}

/* Styling for buttons and text inside the card body */
.project-card-view .card-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 15px;
  filter: none !important;
  opacity: 1 !important;
  transition: none !important; /* Disable any transition */
  text-align: left; /* Ensures other text aligns to the left */
}

/* Card title */
.project-card-view .card-title {
  text-align: center !important; /* Center the title horizontally */
  margin: 0 auto; /* Ensure it aligns to the center of the card */
  font-weight: bold; /* Optional: Make the title bold */
  width: 100%; /* Ensure the title spans the width of the card */
  filter: none !important; /* Ensure no blur */
  opacity: 1 !important; /* Ensure full opacity */
  transition: none !important; /* Disable transition */
}

.project-card-view .card-text,
.live-button {
  flex-grow: 0;
  margin-bottom: 10px;
  padding: 0;
  filter: none !important; /* Ensure no blur */
  opacity: 1 !important; /* Ensure full opacity */
  transition: none !important; /* Disable transition */
  align-self: center; /* Center align text */
  font-family: 'Verdana', sans-serif; /* Change font family */
}

.live-button {
  width: 100px;
}

/* Remove any blur or opacity from modal content as well */
.custom-modal .modal-content {
  background: transparent;
  border: none;
  box-shadow: none;
  filter: none !important; /* No blur */
  opacity: 1 !important; /* Full opacity */
  transition: none !important; /* Disable any transition */
}

.custom-modal .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.8); /* Dark background with transparency */
}
.icon-circle p {
  margin: 0; /* Remove default margin */
  font-size: 12px; /* Adjust font size */
  color: #666; /* Adjust text color */
  text-align: center; /* Center the text */
}
.icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50px; /* Fixed height for all icon containers */
  position: relative; /* For positioning the "Coming Soon" label */
}
.icon-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; /* Adjust the size of the circle */
  height: 40px; /* Adjust the size of the circle */
  background-color: white; /* White background */
  border-radius: 50%; /* Makes it a circle */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow */
}

.icon-circle img {
  width: 24px; /* Ensure the icon size is consistent */
  height: 24px; /* Ensure the icon size is consistent */
}

.coming-soon-label {
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  position: absolute; /* Position the label below the icon */
  bottom: -15px; /* Adjust this value to position the label */
  width: 100%; /* Ensure the label spans the width of the container */
  white-space: nowrap; /* Prevent text from wrapping */
}

/* LTR styles */
.coming-soon-label:not(.rtl) {
  left: -15px; /* Adjust for LTR */
  right: auto; /* Reset right positioning */
}
.coming-soon-label.rtl {
  right: 9px !important; /* Adjust for RTL */
  left: auto; /* Reset left positioning */
}

.enlarged-image {
  max-width: 80vw; /* Responsive max width relative to viewport */
  max-height: 70vh; /* Responsive max height relative to viewport */
  object-fit: contain; /* Maintains aspect ratio without cropping */
}
.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 5px;
  color: white;
  font-size: 24px;
  z-index: 1000; /* Ensure it's above the image */
}
/* Modal content styling for centering */
.custom-modal .modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
/* Mobile-specific styling for enlarged image */
@media (max-width: 768px) {
  .enlarged-image {
    max-width: 80vw; /* Adjust as needed */
    max-height: 68vh; /* Adjust as needed */
    width: 100%;
    max-width: 90%;
    margin: 0 5px;
  }
  .close-icon {
    right: 28px; /* Adjust the position for mobile view */
  }
}




