/* Increase the size of the video and position it */
.myAvtar video.video-player {
    max-width: 100%; /* Ensure it doesn't overflow */
    width: 400px; /* Set a fixed width (adjust as needed) */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Optional: Add rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Add a shadow */
    position: relative; /* Use relative or absolute positioning */
    top: 30px; /* Move the video down by 20px */
  }
  
  /* Optional: Add hover effect */
  .myAvtar video.video-player:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
    transition: transform 0.3s ease; /* Smooth transition */
  }
  /* Align the video using Flexbox */
.myAvtar {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 100%; /* Ensure the container takes full height */
  }
  
  .myAvtar video.video-player {
    max-width: 100%; /* Ensure it doesn't overflow */
    width: 500px; /* Set a fixed width (adjust as needed) */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Optional: Add rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Add a shadow */
  }
  
  /* Optional: Add hover effect */
  .myAvtar video.video-player:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
    transition: transform 0.3s ease; /* Smooth transition */
  }
  /* Style for the full-screen button */
/* Style for the full-screen button */
.myAvtar button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    transition: background-color 0.3s ease;
  }
  
  /* Hover effect for the button */
  .myAvtar button:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  /* Style for the video in full-screen mode */
video.video-player:fullscreen {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Maintain aspect ratio */
  border-radius: 0; /* Remove border radius in full-screen */
}