.whatsapp-container {
    position: fixed;
    left: 20px;
    bottom: 220px; /* Adjusting position to be 200px higher */
    z-index: 1000;
  }
  
  .whatsapp-icon {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    width: 60px; /* Increase size of the icon container */
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .whatsapp-numbers {
    position: absolute;
    top: 0; /* Aligns the top of the menu with the icon */
    left: 70px; /* Positions the menu to the right of the icon */
    background-color: transparent;
    border: 1px solid #a24dd386;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    margin-top: 8px;
    display: flex;
    flex-direction: column;
  }
  
  .whatsapp-numbers a {
    display: block;
    color: #ffffff;
    text-decoration: none;
    margin: 5px 0;
  }
  
  .whatsapp-numbers a:hover {
    text-decoration: underline;
  }
  /* Media query for mobile view */
@media (max-width: 768px) {
    .whatsapp-container {
      bottom: 40px; /* Move the icon further down on mobile view */
    }
  }