.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*accisibilty /
/* Grayscale */
body.grayscale {
  filter: grayscale(100%);
}

/* High Contrast */
body.high-contrast {
  filter: contrast(200%);
}

/* Negative Contrast */
body.negative-contrast {
  filter: invert(100%);
}

/* Light Background */
body.light-background {
  background-color: #f4f4f4 !important;
  color: #333 !important;
}

body.light-background a {
  color: #0066cc !important;
}

/* Underline Links */
body.underline-links a {
  text-decoration: underline;
}

/* Readable Font */
body.readable-font {
  font-family: Arial, sans-serif !important;
}
