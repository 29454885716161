
  
  /* Contact section for contact page only */
  .contact-page-section {
    
    position: relative !important;
    padding-top: 150px !important;
    padding-bottom: 30px !important;
    background-image: var(--section-background-color) !important;
    color: white !important;
  }
  
  
  /* Contact form container styling */
  .contact-form {
  box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important;
  color: white !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
  }
  
  /* Contact form labels styling */
  .contact-page-label {
    color: var(--imp-text-color);
      color: white; /* Change text color to white */
      font-size: 1.1em; /* Adjust font size for a slightly larger text */

  
  }


 /* Contact input fields styling */
.contact-input {
  background-color: rgba(255, 255, 255, 0.361) !important; /* Slight transparency */
  color: rgb(255, 255, 255) !important;
  border: 1px solid rgba(255, 255, 255, 0.6) !important;
  padding: 10px !important;
  border-radius: 8px !important;
  margin-bottom: 15px !important;
  transition: background-color 0.3s ease !important; /* Smooth transition effect */
}

/* Focus styling for contact input fields */
.contact-input:focus {
  background-color: white !important; /* Solid white background on focus */
  color: rgb(16, 1, 1) !important;
  box-shadow: none !important;
  border-color: rgba(255, 255, 255, 0.8) !important; /* Slightly brighter border on focus */
}

::placeholder {
  color: rgba(237, 233, 233, 0.893) !important; /* Light color for the placeholder */
  opacity: 1 !important; /* Ensures the color is applied */
}
/* Change placeholder color on focus */
.contact-input:focus::placeholder {
  color: rgba(16, 1, 1, 0.5) !important; /* Darker color for placeholder on focus */
}
  /* Contact form button styling */
  .contact-btn {
    margin-top: 50px;
    background-color: var(--imp-text-color) !important;
    border: none !important;
    transition: background-color 0.3s ease !important;
    color: white !important;
    padding: 15px 30px !important;
    font-size: 1.2em !important;
    border-radius: 5px !important;
    cursor: pointer !important;
    width: 100% !important;

    /* Flexbox centering */
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;

    /* Set specific height */
    height: 40px !important; /* Adjust height as needed */
    line-height: 1.2 !important;
}

  
  .contact-btn:hover {
    background-color: #a24dd386;
  }
  .custom-alert {
    background-color: #a24dd386 !important; /* Custom background color */
    color: white !important; /* White text */
    border: none !important; /* Remove border */
    font-size: 1em !important; /* Adjust font size if needed */
    padding: 15px !important; /* Optional: Add padding for spacing */
    border-radius: 5px !important; /* Optional: Add border-radius for rounded corners */
    text-align: center !important; /* Center-align text */
}
